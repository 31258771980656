export const simulation = /* glsl */ `
precision mediump float;

uniform sampler2D tMap;
uniform sampler2D texturePosition;
uniform float uTime;
varying vec2 vUv;
   



void main() {


  vec3 curlPos = texture2D(texturePosition, vUv).xyz;
  vec3 pos = texture2D(tMap, vUv ).xyz;


   gl_FragColor = vec4( pos, 1.);
}
`
