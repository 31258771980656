export const fragment = /* glsl */ `
precision mediump float;
varying vec2 vUv;
uniform float uTime;
uniform vec3 uColor1;
uniform vec3 uColor2;
uniform vec3 uColor3;

vec3 toRGB(vec3 color) {
  return vec3(color.r/255., color.g/255., color.b/255.);
}

void main() {

  vec2 st = vUv;
  vec3 color = toRGB(uColor1);
  vec3 color2 = toRGB(uColor2);
  vec3 color3 = toRGB(uColor3);
  color = mix(color, color2, st.x);
  color = mix(color, color3, st.y);

  gl_FragColor = vec4(color,1.);
}

`
