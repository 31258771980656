import store from '../store'
import { Vec2, Program, Mesh, Geometry, GPGPU, Plane } from 'ogl'
import { vertex } from '../glsl/vertexParticle'
import { fragment } from '../glsl/fragmentParticle'
import { simulation } from '../glsl/fragmentSimulation'

export default class FBO {
  constructor(obj = {}) {
    const gl = obj.gl

    this.plane = {}

    this.ogl = {
      gl: gl,
    }

    this.WIDTH = 128

    this.setup()
  }

  setup() {
    const { gl } = this.ogl

    const initialPositionData = new Float32Array(this.WIDTH * this.WIDTH * 4)
    const positions = new Float32Array(this.WIDTH * this.WIDTH * 3)
    const reference = new Float32Array(this.WIDTH * this.WIDTH * 2)

    for (let i = 0; i < initialPositionData.length; i = i + 4) {
      let x = Math.random()
      let y = Math.random()
      let z = Math.random()
      initialPositionData[i] = x
      initialPositionData[i + 1] = y
      initialPositionData[i + 2] = z
      initialPositionData[i + 3] = 1
    }

    for (let i = 0; i < this.WIDTH * this.WIDTH; i++) {
      let x = Math.random()
      let y = Math.random()
      let z = Math.random()

      let xx = (i % this.WIDTH) / this.WIDTH
      let yy = ~~(i / this.WIDTH) / this.WIDTH

      reference.set([xx, yy], i * 2)
      positions.set([x, y, z], i * 3)
    }

    const positionVariable = new GPGPU(gl, { data: initialPositionData })

    positionVariable.addPass({
      fragment: simulation,
      uniforms: {
        uTime: { value: this.time },
        texturePosition: { value: 0 },
      },
    })

    const planeGeometry = new Plane(gl, {
      widthSegments: 10,
      heightSegments: 10,
    })

    const geometry = new Geometry(gl, {
      position: { size: 3, data: positions },
      reference: { size: 2, data: reference },
    })

    const program = new Program(gl, {
      vertex: vertex,
      fragment: fragment,
      transparent: true,
      uniforms: {
        uTime: { value: 0 },
        uScale: { value: 1 },
        uScaleChange: { value: 0.9 },
        uRes: { value: new Vec2(store.vw, store.vh) },
        uSize: { value: new Vec2(0, 0) },
        uPlaneSizes: { value: new Vec2(0, 0) },
        positionTexture: { value: null },
        uColor1: { value: [62, 50, 39] },
        uColor2: { value: [218, 204, 183] },
        uColor3: { value: [227, 207, 188] },
        uFrequency: { value: 0.5 },
        uSpeed: { value: 0.5 },
      },
    })

    const mesh = new Mesh(gl, {
      mode: gl.POINTS,
      geometry: geometry,
      program: program,
    })

    mesh.frustumCulled = false

    setTimeout(() => {
      mesh.frustumCulled = true
    }, 1000)

    this.plane.geometry = geometry
    this.plane.program = program
    this.plane.mesh = mesh
    this.plane.position = positionVariable

    return this.plane
  }
}
